/**
 * The `ValidationRules` class provides a collection of static validation rules and utility methods
 * for validating various inputs, such as file extensions, file sizes, email addresses, URLs, and more.
 * These rules are intended to be used for form validation and file upload checks across the application.
 *
 * ### Key Features:
 *
 * - **General Validation Rules**: Provides common validation rules like `RequiredName`, `RequiredNumber`, `RequiredEmail`, etc.
 * - **File Validation**: Includes file type validation methods like `PngImageUpload` and dynamic file format validation via Vuex getters (e.g., `getValidModelFormats`).
 * - **Email Validation**: Implements regex-based email validation, including optional validation for lists of emails.
 * - **Image Validation**: Handles image size validations for specific dimensions.
 *
 * ### Example Usage:
 *
 * // In <script>
 * import { ValidationRules } from '@/utils/input-validation'
 * this.nameRules = ValidationRules.RequiredName
 *
 * This class is designed to be used statically, with no instance creation necessary.
 */

import { UtilsConst } from '@/constants'
import store from '@/store'
import { ImageValidationResult } from '@/store/types'
import sizeOf from 'image-size'
import isEmail from 'validator/es/lib/isEmail'

export class ValidationRules {
  public static readonly RequiredName: any = [
    (v: any) => v != null || 'Name is required',
    (v: any) => v !== '' || 'Name is required',
    (v: any) => (v != null && v.length <= 254) || 'Name must be less than 254 characters',
  ]

  public static readonly RequiredCompanyRole: any = [
    (v: any) => (v != null && v.value != null) || 'Select company role',
    (v: any) => (v != null && v.value !== '') || 'Select company role',
  ]

  public static readonly RequiredTeamRole: any = [
    (v: any) => v.Name != null || 'Select team role',
    (v: any) => v.Name !== '' || 'Select team role',
  ]

  public static readonly RequiredTeam: any = [(v: any) => v.value != null || 'Select a team', (v: any) => v.value !== '' || 'Select a team']

  public static readonly RequiredTeamUser: any = [
    (v: any) => v.value != null || 'Select a team member',
    (v: any) => v.value !== '' || 'Select a team member',
  ]

  public static readonly PngImageUpload: any = [(v: any) => !v || v.type === 'image/png' || 'Image must be a png']

  // Instead of static const value, we should dynamically access getter
  // every time we need the value to have real-time updates.
  public static getValidModelFormats(): string[] {
    return store.getters['app/allowedModelFileFormats']
  }
  public static getValidModelFormatsString(): string {
    return ValidationRules.getValidModelFormats().join(',')
  }
  // There is a vague order to how we show formats: obj/fbx -> consumer formats first, the rest is cad/pro
  public static getValidModelFormatsPretty(): string {
    return ValidationRules.getValidModelFormats().join(', ').toUpperCase()
  }

  public static readonly ModelUploadFiles: any = [
    (files: File[]) => {
      if (files === undefined || files === null || files.length === 0) {
        return 'No files provided'
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        if (file.size === 0) {
          return `${file.name} size must be greater than 0 bytes`
        }

        if (file.size > 300000000) {
          return `${file.name} size must be less than 300MB`
        }

        if (file.name === undefined || file.name === null) {
          return `${file.name} must be of type: ${ValidationRules.getValidModelFormatsPretty()}`
        }
        let v = file.name.split('.').pop()
        v = v === undefined ? 'noextension' : v // Protection from undefined
        v = '.' + v.toString().toLowerCase()
        const arrFormats = ValidationRules.getValidModelFormats()
        if (arrFormats.indexOf(v) == -1) {
          return `${file.name}: unsupported format. We currently only support ${ValidationRules.getValidModelFormatsPretty()}`
        }
      }
      return true
    },
  ]

  public static readonly RequiredNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number must be a number',
  ]

  public static readonly RequiredNoneNegativeNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number is required',
    (v: any) => v >= 0 || 'Positive number is required',
  ]

  public static readonly RequiredPositiveNumber: any = [
    (v: any) => v != null || 'Number is required',
    (v: any) => v !== '' || 'Number is required',
    (v: any) => !isNaN(v) || 'Number is required',
    (v: any) => v > 0 || 'Positive number is required',
  ]

  private static readonly jigHashIdPattern = /^[a-zA-Z0-9]{8}$/
  public static readonly RequiredJigHashID: any = [
    (v: any) => v != null || 'Jig Hash ID is required',
    (v: any) => v !== '' || 'Jig Hash ID is required',
    (v: any) => {
      const regexp = new RegExp(ValidationRules.jigHashIdsPattern)
      const res = regexp.test(v)
      return res || 'Invalid Jig Hash ID.'
    },
  ]

  private static readonly jigHashIdsPattern = /^\b[a-zA-Z0-9]{8}\b(?:,\s*\b[a-zA-Z0-9]{8}\b)*$/
  public static readonly RequiredJigHashIDs: any = [
    (v: any) => v != null || 'Jig Hash ID is required',
    (v: any) => v !== '' || 'Jig Hash ID is required',
    (v: any) => {
      const regexp = new RegExp(ValidationRules.jigHashIdsPattern)
      const res = regexp.test(v)
      return res || 'Invalid Jig Hash ID(s) or invalid delimiter. Separate multiple IDs using "," comma character.'
    },
  ]

  // Auth0 now uses validatorjs to validat email
  public static readonly RequiredEmail: any = [
    (v: any) => v != null || 'Required.',
    (v: any) => v !== '' || 'Required.',
    (v: any) => (typeof v === 'string' && isEmail(v)) || 'Invalid email.',
  ]

  public static readonly RequiredBulkEmails: any = [
    (v: any) => v != null || 'Required.',
    (v: any) => v !== '' || 'Required.',
    (v: any) => {
      if (typeof v !== 'string') {
        return false
      }

      let res = true
      const emails = v.trim().split(/\s*,\s*/)

      for (const email of emails) {
        if (!isEmail(email)) {
          res = false
          break
        }
      }

      return res || 'Invalid email.'
    },
  ]

  public static readonly OptionalEmail: any = [
    (v: any) => {
      if (v == null || v === '') {
        return true
      }
      return isEmail(v) || 'Invalid email.'
    },
  ]

  public static readonly OptionalEmailList: any = [
    (emails: string[]) => {
      // A valid array must be supplied
      if (emails === null || emails === undefined) {
        return false
      }
      // the array can be empty
      if (emails.length == 0) {
        return true
      }

      let emailCount = emails.length
      let displayIndex = 1

      for (const v of emails) {
        if (v === '' || v == null) {
          let errMsg = ''
          if (emailCount === 1) {
            errMsg = 'Invalid email address'
          } else {
            errMsg = 'Invalid email address at index: ' + displayIndex
          }
          return false || errMsg
        }
        if (!isEmail(v)) {
          const errMsg = 'Invalid email address: ' + v
          return isEmail(v) || errMsg
        }
        displayIndex++
      }
      return true
    },
  ]

  private static readonly urlPartialPattern = /\..|:[0-9]+/i
  // private static readonly urlPattern = //
  public static readonly OptionalUrl: any = [
    (v: any) => {
      if (v === undefined || v === null || v === '') {
        return true
      }

      let tempVal = v

      if (!tempVal.startsWith('mailto:')) {
        const regexp = new RegExp(ValidationRules.urlPartialPattern)
        const res = regexp.test(tempVal)
        if (res && !tempVal.includes('://')) {
          tempVal = `http://${tempVal}`
        }
      }

      // Use JS URL() constructor to validate url value.
      try {
        new URL(tempVal)
        return true
      } catch (error) {
        return 'Invalid url.'
      }
    },
  ]

  // private static readonly urlPattern = //
  public static readonly OptionalTags: any = [
    (v: any) => {
      if (v === undefined || v === null || v === '') {
        return true
      }

      const hasNoComma = v && !v.includes(',')
      if (!hasNoComma) return 'Comma is not allowed'

      const regexp = new RegExp(UtilsConst.nonPrintableCopyRegex)
      const res = regexp.test(v)

      return !res || 'Invalid character detected'
    },
  ]

  public static ImageSizeLibraryThumbnail(v: File): Promise<ImageValidationResult> {
    return ValidationRules.ImageSize(v, 0, 0, 320, 287)
  }

  public static ImageSizeJigThumbnail(v: File): Promise<ImageValidationResult> {
    return ValidationRules.ImageSize(v, 0, 0, 512, 386)
  }

  public static ImageSize(
    v: File,
    minWidth: number,
    minHeight: number,
    maxWidth: number,
    maxHeight: number
  ): Promise<ImageValidationResult> {
    return new Promise<ImageValidationResult>(async (resolve) => {
      const result: ImageValidationResult = {
        valid: false,
        xMin: 0,
        yMin: 0,
        xMax: maxWidth,
        yMax: maxHeight,
        x: 0,
        y: 0,
      }

      // Async load image details and check dimensions
      const fr = new FileReader()
      fr.onload = () => {
        const ab: any = fr.result
        const buffer = new Buffer(ab)
        const imageDetails = sizeOf(buffer)
        result.valid =
          imageDetails.width >= minWidth &&
          imageDetails.width <= result.xMax &&
          imageDetails.height >= minHeight &&
          imageDetails.height <= result.yMax
        result.x = imageDetails.width
        result.y = imageDetails.height
        result.xMin = minWidth
        result.xMax = maxWidth
        result.yMin = minHeight
        result.yMax = maxHeight
        resolve(result)
      }

      fr.onabort = () => {
        resolve(result)
      }

      fr.onerror = () => {
        resolve(result)
      }
      fr.readAsArrayBuffer(v)
    })
  }
}
