/**
 * The Filters class provides a set of utility filters for use in Vue2 applications.
 * These filters are designed to format dates, handle thumbnail URLs, and convert arrays
 * to CSV strings, among other common tasks.
 *
 * original vue2 filter is deperecated in vue3, plus
 * typescript related plugins are not supporting filters,
 * recrafted vue2 filters into filters help file.
 * When upgrading to vue3 we can migrate this file into `composable`
 */
import { AppConst } from '@/constants'
import { LibraryCategory } from '@/store/modules/teamlibrary/types'

const thumbnailPlaceHolderImageUrl = '/img/Thumbnail_PlaceholderPro_512.png'

export class Filters {
  // eslint-disable-next-line lines-around-comment
  /**
   * Formats a given date string into a localized "date, time" format.
   *
   * @param {string} value - The date string to format (in UTC or ISO format).
   * @returns {string} - The formatted date and time in the 'dd/mm/yyyy, hh:mm' format
   *                      (localization depends on the user's locale, e.g., '26/08/2021, 12:34'),
   *                      or 'Not Set' if the date is invalid or not provided.
   */
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  // timeStyle short will get rid of second part. timeStyle should be used together with dateStyle.
  public static formatDate(value: string, formatOption: Intl.DateTimeFormatOptions = { dateStyle: 'short', timeStyle: 'short' }): string {
    if (value) {
      if (Date.parse(value) === 0) {
        return 'Not Set'
      } else {
        // Convert UTC date value to local timezone date object.
        const localeDate = new Date(value)

        // Will return localised date string in "date, time" format. For example: dd/mm/yyyy, hh:mm
        // If you need to get rid of the comma in the output string, get date and time loclae strings separately.
        return localeDate.toLocaleString(undefined, formatOption)
      }
    } else {
      return 'Not Set'
    }
  }

  /**
   * Formats a given date string into a human-readable format.
   *
   * @param {string} value - The date string to format (in UTC or ISO format).
   * @returns {string} - The formatted date in the 'Weekday, Month Day, Year' format
   *                      (e.g., 'Thursday, 26 August 2021') according to the local timezone,
   *                      or 'Not Set' if the date is invalid or not provided.
   */
  public static formatDateShort(value: string): string {
    if (value) {
      if (Date.parse(value) === 0) {
        return 'Not Set'
      } else {
        // Convert UTC date value to local timezone date object.
        const localeDate = new Date(value)
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
        // Format date into Weedkay, DateMonth, Year order. Result varies according to default locale. For example: Thursday, 26 August 2021
        const options: any = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }

        return localeDate.toLocaleDateString(undefined, options)
      }
    } else {
      return 'Not Set'
    }
  }

  /**
   * Checks if the given thumbnail URL contains a placeholder image.
   *
   * @param {string} value - The thumbnail URL to check.
   * @returns {boolean} - Returns true if the URL is defined, not null,
   *                      and includes the substring 'jig-placeholder'; otherwise, returns false.
   */
  public static hasThumbnailPlaceHolder(value: string): boolean {
    return value !== undefined && value !== null && value.includes('jig-placeholder')
  }

  /**
   * Maps a thumbnail URL to a custom file type, with special handling for placeholder images.
   *
   * @param {string} value - The original thumbnail URL.
   * @param {string} [fileType='png'] - The desired file type to convert the URL to (default is 'png').
   * @returns {string} - Returns the mapped thumbnail URL:
   *                     - If the original URL is a placeholder image, it returns the placeholder image URL.
   *                     - Otherwise, it returns the modified URL with the specified file type,
   *                       prefixed by the API domain.
   */
  public static thumbnailURLMapCustomType(value: string, fileType: string = 'png'): string {
    if (value) {
      let isPlaceholder = false
      if (value === '/images/jigs/jig-placeholder-color.png' || value === '/images/jig/jig-placeholder-color.png') {
        value = thumbnailPlaceHolderImageUrl
        isPlaceholder = true
      }
      if (fileType !== '') {
        value = value.substr(0, value.lastIndexOf('.'))
        value += '.' + fileType
      }
      // Thumbnails are served from: a static directory (if it's placeholder) or the jig-api, eg: https://api.jig.space.
      // - The placeholder comes from the public/img directory
      // - If it starts with '/images/models/' then it's the old thumbnails
      // - If it starts with '/images/jigs/' then it's the old thumbnails
      // - If it starts with '/library/' then it's the new thumbnails
      // - If it starts with '/tenants/v1/{tenantId}/libary/v1/{itemId}/thumbnail' then it's the new thumbnails served from the jig-api and secured properly (in this case don't set the img src, use the auth-image component)
      if (isPlaceholder) {
        return window.location.origin + value
      } else {
        return AppConst.apiDomain + value
      }
    }
    return ''
  }

  public static thumbnailURLMap(value: string): string {
    return this.thumbnailURLMapCustomType(value)
  }

  /**
   * Converts an array of category indices into a CSV string of category names.
   *
   * @param {number[]} categoryIndicies - An array of category indices to be converted.
   * @param {Map<number, LibraryCategory>} categoriesMap - A map that associates category indices with their corresponding category objects.
   * @returns {string} - Returns a CSV string containing the display names of the categories,
   *                     or an empty string if no valid categories are found.
   */
  public static categoryIndiciesToCsvString(categoryIndicies: number[], categoriesMap: any): string {
    let categoryNames = ''
    if (categoryIndicies != null && categoriesMap != null) {
      let i = 0
      for (const categoryIndex of categoryIndicies) {
        if (i > 0) {
          categoryNames = categoryNames.concat(', ')
        }
        const category = categoriesMap.get(categoryIndex) as LibraryCategory
        if (category === undefined || category === null) {
          continue
        }
        categoryNames = categoryNames.concat(category.DisplayName)
        i++
      }
    }
    return categoryNames
  }

  /**
   * Converts an array of LibraryCategory objects into a map for quick lookups by category ID.
   *
   * @param {LibraryCategory[]} categories - An array of LibraryCategory objects to be converted.
   * @returns {Map<number, LibraryCategory>} - Returns a Map where the keys are category IDs
   *                                            and the values are the corresponding LibraryCategory objects.
   */
  public static categoriesArrayToMap(categories: LibraryCategory[]): Map<number, LibraryCategory> {
    const categoriesMap = new Map()
    for (const c of categories) {
      categoriesMap.set(c.CategoryId, c)
    }
    return categoriesMap
  }

  /**
   * Converts an array of strings into a CSV-formatted string with a specified delimiter.
   *
   * @param {string[]} values - An array of strings to be converted into CSV format.
   * @param {string} [delimiter=', '] - The delimiter to use between values in the resulting string (default is ', ').
   * @returns {string} - Returns a CSV string containing the non-empty values from the input array,
   *                     separated by the specified delimiter. If the input array is null,
   *                     an empty string is returned.
   */
  public static arrayToCsvString(values: string[], delimiter: string = ', '): string {
    let output = ''
    if (values === null) {
      return output
    }
    let i = 0
    for (const v of values) {
      if (v === '') {
        continue
      }
      if (i > 0) {
        output = output.concat(delimiter)
      }
      output = output.concat(v)
      i++
    }
    return output
  }
}
