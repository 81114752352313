import { TenantUser } from '@/store/modules/app/types'
import { JigMetadata } from '@/store/modules/jig/types'

/**
 * This file stores shared function between mixin and vue files
 * that may result in Circular Dependency.
 */
export class JigLockSharedFunction {
  public static jigLockOwnerName(jigMetadata: JigMetadata, tenantUsers: TenantUser[]): string {
    if (jigMetadata.UserActivity == null || jigMetadata.UserActivity.LockedByUID == null || jigMetadata.UserActivity.LockedByUID <= 0) {
      return ''
    }

    let editorUserName = ''

    for (let i = 0; i < tenantUsers.length; i++) {
      const user = tenantUsers[i]
      if (user.Uid === jigMetadata.UserActivity.LockedByUID) {
        editorUserName = user.name || user.email
        break
      }
    }

    return editorUserName
  }

  public static jigLockUIDDisplay(jigMetadata: JigMetadata): string {
    let uidDisplay: string = 'N/A'

    if (jigMetadata.UserActivity && jigMetadata.UserActivity.LockedByUID && jigMetadata.UserActivity.LockedByUID > 0) {
      uidDisplay = `${jigMetadata.UserActivity.LockedByUID}`
    }

    return `UID: ${uidDisplay}`
  }

  public static isJigLockActive(jigMetadata: JigMetadata): boolean {
    let isLockActive: boolean = false

    if (jigMetadata.UserActivity && jigMetadata.UserActivity.LockExpiry) {
      isLockActive = new Date(jigMetadata.UserActivity.LockExpiry) >= new Date()
    }

    return isLockActive
  }

  public static isJigLockOwnedByOthers(jigMetadata: JigMetadata, currentUserUid: number): boolean {
    if (jigMetadata.UserActivity && jigMetadata.UserActivity.LockedByUID && jigMetadata.UserActivity.LockedByUID > 0) {
      return jigMetadata.UserActivity.LockedByUID !== currentUserUid
    }

    return false
  }
}
