import { getInstance } from '@/plugins/auth0'

export default {} // exported here and imported in App.vue so that webpack includes this js

/* eslint-disable no-console */

const isAllowedEmail = () => {
  const authService = getInstance()
  return {
    email: authService.user.email,
    isJigSpaceEmail: authService.user.email.endsWith('@jig.space'),
  }
}

const isInDevEnv = () => window.location.hostname === 'localhost' || window.location.hostname === 'dashboard.dev.jig.space'

const isLoggingEnabled = () => {
  // We always log for localhost and dev
  const publicPaths = ['/callback', '/pre-login', '/oops', '/not-found']
  const isPublicPage = publicPaths.includes(window.location.pathname)
  let isEnabledOnPublicPages = false
  let isEnabledOnPrivatePages = false

  if (sessionStorage.getItem('enableLogging') === 'true') {
    // If logging is manually turned on, we allow it on public pages regardless
    isEnabledOnPublicPages = isPublicPage
    isEnabledOnPrivatePages = !isPublicPage

    // If a user with none @jig.space domain email tries to turn on log, force disble it in pages requires login
    if (isEnabledOnPrivatePages && !isAllowedEmail().isJigSpaceEmail) {
      isEnabledOnPrivatePages = false
      sessionStorage.setItem('loggingNotAllowed', 'true')
      window.disableLogging(true)
    }
  }

  return isInDevEnv() || isEnabledOnPublicPages || isEnabledOnPrivatePages
}

// Functions to enable or disable logging manually per session
window.enableLogging = () => {
  sessionStorage.setItem('enableLogging', 'true')
  window.whiteLog('Logging has been enabled for this session.')
}

window.disableLogging = (forceDisable = false) => {
  if (forceDisable) {
    window.consoleWarn('Logging has been disabled for this session as a none staff account tried to turn on log.')
  } else {
    window.whiteLog('Logging has been disabled for this session.')
  }
  sessionStorage.removeItem('enableLogging')
}

const toggleLog = () => {
  if (isLoggingEnabled()) {
    // Turn off log
    if (isInDevEnv()) {
      jigConsoleLog('cyan', 'Turn off log function called. However log is not turned off as this is dev environment.')
    } else {
      window.disableLogging()
    }
  } else if (sessionStorage.getItem('loggingNotAllowed') === 'true') {
    // Try turn on log, however none @jig.space email tries to do so, disallow it.
    window.consoleWarn('Turn on log by users with none staff account is not allowed for this session.')
  } else {
    // Turn on log
    window.enableLogging()
  }
}

const jigConsoleLog = (color = 'black', ...msgParams) => {
  if (!isLoggingEnabled()) {
    return
  }

  if (color === 'black') {
    console.log(...msgParams)
  } else {
    let ansiCode = '\x1b[30m%s\x1b[0m'
    let logType = 'info'

    switch (color) {
      case 'red':
        ansiCode = '\x1b[31m%s\x1b[0m'
        logType = 'error'
        break
      case 'green':
        ansiCode = '\x1b[32m%s\x1b[0m'
        break
      case 'yellow':
        ansiCode = '\x1b[33m%s\x1b[0m'
        logType = 'warn'
        break
      case 'blue':
        ansiCode = '\x1b[34m%s\x1b[0m'
        logType = 'log'
        break
      case 'magenta':
        ansiCode = '\x1b[35m%s\x1b[0m'
        break
      case 'cyan':
        ansiCode = '\x1b[36m%s\x1b[0m'
        break
      case 'white':
        ansiCode = '\x1b[37m%s\x1b[0m'
        break
      default:
        break
    }
    console[logType](ansiCode, ...msgParams)
  }
}

const jigConsoleError = (message, errorData) => {
  if (!isLoggingEnabled) {
    return
  }

  console.error(message, errorData || '')
}

const jigConsoleWarn = (...params) => {
  if (!isLoggingEnabled) {
    return
  }

  console.warn(...params)
}

// Usage: in chrome console as `toggleLog()`
window.toggleLog = toggleLog

// Usage:
// - in vue files as `consoleLog('message')`
// - in js files as `consoleLog('message')`
// - in ts files as `consoleLog('message')` but you have to `declare var consoleLog : any;` at the top of the file
window.consoleLog = (...msgParams) => jigConsoleLog('black', ...msgParams) // Neutral or general information, default color
window.cyanLog = (...msgParams) => jigConsoleLog('cyan', ...msgParams) // Neutral or general information, stands out without being too aggressive
window.redLog = (...msgParams) => jigConsoleLog('red', ...msgParams) // errors or critical failures
window.greenLog = (...msgParams) => jigConsoleLog('green', ...msgParams) // success or positive results
window.yellowLog = (...msgParams) => jigConsoleLog('yellow', ...msgParams) // warnings, potential issues without a critical failure
window.blueLog = (...msgParams) => jigConsoleLog('blue', ...msgParams) // debugging output
window.magentaLog = (...msgParams) => jigConsoleLog('magenta', ...msgParams) // highlight critical information
window.whiteLog = (...msgParams) => jigConsoleLog('white', ...msgParams) // output that doesn’t require special attention
// Usage:
// - in vue files as `consoleError('message', error)`, `consoleError('message', payload)`
// - in js files as `consoleError('message', error)`, `consoleError('message', payload)`
// - in ts files as `consoleError('message', error)`, `consoleError('message', payload)` but you have to `declare var consoleError : any;` at the top of the file
window.consoleError = jigConsoleError
window.consoleWarn = jigConsoleWarn
