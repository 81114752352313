/**
 * A function to convert email string to a hsl color string.
 * Usage
 * const converter = new EmailToHSL()
 * console.log(converter.convert('Bertron-simpson@gmail.com').toString())
 *
 * console.log(converter.convert('Numa@jig.space').toString())
 * console.log(converter.convert('Ziryan@jig.space').toString())
 * console.log(converter.convert('Sam.granleese@jig.space').toString())
 * console.log(converter.convert('DavyLaBoube@wanadoo.fr').toString())
 */

/* eslint-disable no-console */
export class EmailToHSL {
  FnvPrime: bigint
  FnvOffsetBasis: bigint

  constructor() {
    this.FnvPrime = BigInt('0x01000193')
    this.FnvOffsetBasis = BigInt('0x811C9DC5')
  }

  convert(email: string | null | undefined) {
    if (email == null) {
      console.warn('email-to-hsl convert terminated as email is empty', email)

      return ''
    }

    let sum: number = 0
    for (let i = 0; i < email.length; i++) {
      const hash: number = this.fnv1aHash(email[i])
      const positionalValue: number = (hash % 15) * (i + 1)
      sum += positionalValue
    }

    const hue: number = sum % 360
    const saturation: number = 80
    const lightness: number = 90

    return new HSLColor(hue, saturation, lightness)
  }

  fnv1aHash(toHash: string) {
    let hash: bigint = this.FnvOffsetBasis

    for (let i = 0; i < toHash.length; i++) {
      hash ^= BigInt(toHash.charCodeAt(i))
      hash *= this.FnvPrime
    }

    const result: bigint = hash & BigInt('0xFFFFFFFF')

    return parseInt(result.toString(), 10) // Ensure unsigned 32-bit integer
  }
}

class HSLColor {
  hue: number
  saturation: number
  lightness: number

  constructor(hue: number, saturation: number, lightness: number) {
    this.hue = hue
    this.saturation = saturation
    this.lightness = lightness
  }

  toHSLValue() {
    return {
      hue: this.hue,
      saturation: this.saturation,
      lightness: this.lightness,
    }
  }

  toHSLString() {
    return `hsl(${this.hue} ${this.saturation}% ${this.lightness}%)`
  }
}
