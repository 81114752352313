import { getInstance } from '@/plugins/auth0'
import { TenantHelpers } from '@/utils/tenant-helpers'

import { AppHelper } from './app-helper'

let authService

const getUid = () => {
  if (!authService) {
    authService = getInstance()
  }

  return authService.uid
}

const getUser = () => {
  if (!authService) {
    authService = getInstance()
  }

  return authService.user
}

const getTenants = () => {
  if (!authService) {
    authService = getInstance()
  }

  return authService.tenants
}

/**
 * Identity tracking function of segment, usually be called
 * right after successful login event to track user details
 *
 * @typedef {Object} AuthData
 * @property {AuthProfileData} profile - audio error code
 * @property {array} tenants - array of user tenants
 * @property {string} message - error message (optional)
 *
 * @typedef {Object} AuthProfileData
 * @property {string} uid - Jig user id
 * @property {string} email - user email
 * @property {string} sub - a string contains signed up platform
 * @property {string} given_name (optional) - exists when signup with google or facebook
 * @property {string} first_name (optional) - exists when signup with apple
 * @property {string} family_name (optional) - exists when signup with google or facebook
 * @property {string} last_name (optional) - exists when signup with apple
 *
 * @param {AuthData} data - auth data
 * @param {array} tenants - array of user tenants
 * @param {Object} extraProperties - any other values that needs to be sent to segment
 */
export const segmentIdentityTracking = (tenants, currentTenant, extraProperties = {}) => {
  // users signed up using google will have given_name and family_name fields
  // users signed up using apple account will have first_name and last_name fields.
  // Users signed up using facebook account will have given_name and family_name fields
  // users signed up using auth have no name fields.
  // profile.sub contains the signed up platform, examples:
  // google-oauth2|113412637137292723975
  // facebook|10157395758925701
  // apple|123456789
  // auth0|5c8748555a25970fe7bd8a67
  const user = getUser()

  const signedupPlatform = user.sub.split('|')[0]
  let firstName = ''
  let lastName = ''
  let fullname = ''
  let authProvider = 'JigSpaceDB'
  let currentTenantName = `${currentTenant.Name} (id: ${currentTenant.ID})`
  let activeTenantNames = ''

  switch (signedupPlatform) {
    case 'google-oauth2':
      firstName = user.given_name
      lastName = user.family_name
      fullname = `${firstName} ${lastName}`
      authProvider = 'Google'
      break
    case 'apple':
      firstName = user.first_name
      lastName = user.last_name
      fullname = `${firstName} ${lastName}`
      authProvider = 'Apple'
      break
    case 'facebook':
      firstName = user.given_name
      lastName = user.family_name
      fullname = `${firstName} ${lastName}`
      authProvider = 'Facebook'
      break
    default:
      break
  }

  if (TenantHelpers.IsSuperUserOrJigStaff()) {
    // Jig staff have access to every tenant and we don't want to list all of them in a massive identify call.
    activeTenantNames = 'JigSpaceStaff'
  } else {
    const activeTenants = tenants.filter((t) => t.Active)
    const devTestTenantRegx = /^(Test)[a-zA-Z]+_20[\s0-9-:.+a-zA-Z]+m=[0-9+.]+$/

    if (activeTenants.length) {
      // tenant name is not unique, need to pass in ID which is the unique identifier
      activeTenantNames = activeTenants.map((t) => `${t.Name.replace(devTestTenantRegx, 'TestTenant')} (id: ${t.ID})`).join(',')
    } else {
      activeTenantNames = getTenants()
        .map((t) => `${t.name.replace(devTestTenantRegx, 'TestTenant')} (id: ${t.id})`)
        .join(',')
    }
  }

  // By default, each of the segment scripts (for dashboard, webflow, buy page) is setting a different anonymous id.
  // So if someone is logged in dashboard, but then goes to webflow, it is not recognising that person using the native Segment id method.
  // We can set the anonymous ID if it already exists to avoid this issue.
  // Details: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/
  //
  // We can pass in anonymouseId as option to both identity and track function and set its value using `analytics.user().anonymousId()`
  // analytics.user().anonymousId() returns user's current `anonymousId`
  // If user’s anonymousId is null (meaning not set), it automatically generats new one and sets it for the user.
  const option = getTrackingAnonymousId()

  // Change to return a promise so on logging out we can properly wait until tracking data has been sent.
  // Otherwise event can not be sent out successfully when user has already been logged out.
  return new Promise((resolve, reject) => {
    try {
      // consoleLog(`
      //   window.analytics.identify(
      //     ${user.email},
      //     {
      //       ...getFieldIfExists('firstName', ${firstName}),
      //       ...getFieldIfExists('lastName', ${lastName}),
      //       ...getFieldIfExists('name', ${fullname.trim()}),
      //       email: ${user.email},
      //       jigUserId: ${getUid()},
      //       tenantName: ${currentTenantName},
      //       ${activeTenantNames},
      //       ${authProvider},
      //       Subscription: ${AppHelper.getTierType(currentTenant.Subscription.TierType) || ''},
      //       ...${JSON.stringify(extraProperties)},
      //     },
      //     ${JSON.stringify(option)},
      //   )
      // `)

      window.analytics.identify(
        user.email,
        {
          ...getFieldIfExists('firstName', firstName),
          ...getFieldIfExists('lastName', lastName),
          ...getFieldIfExists('name', fullname.trim()),
          email: user.email,
          jigUserId: getUid(),
          tenantName: currentTenantName,
          activeTenantNames,
          authProvider,
          Subscription: AppHelper.getTierType(currentTenant.Subscription.TierType || ''),
          ...extraProperties,
        },
        option
      )

      resolve(true)
    } catch (error) {
      consoleLog(`Error happened trying to push segment identify event: ${error}`)
      reject(false)
    }
  })
}

/**
 * Segment event tracking function
 * At this stage we try map data passed in the same as
 * gtm event tracking data.
 *
 * @typedef {Object.<string, *>} gtmEventData
 * @property {string} [action] - (optional)
 * @property {string} [label] - (optional)
 * @property {string} [target] - (optional)
 * @property {string} [target-properties] - (optional)
 * @property {*} [value] - (optional)
 *
 * @param {gtmEventData} data - any data that needs tracking, map gtm event data
 */
export const segmentEventTracking = (event, data) => {
  // See `segmentIdentityTracking()` function for details regarding `anonymousId`
  const option = getTrackingAnonymousId()

  try {
    // consoleLog(`
    //   window.analytics.track(
    //     ${event},
    //     {
    //       Application: 'dashboard',
    //       ...${JSON.stringify(data)},
    //       ...${getEmailIfExists()},
    //     },
    //     ${JSON.stringify(option)},
    //   )
    // `)
    window.analytics.track(
      event,
      {
        Application: 'dashboard',
        ...data,
        ...getEmailIfExists(),
      },
      option
    )
  } catch (error) {
    consoleLog(`Error happened trying to push segment tracking event, ${error}`)
    return false
  }
}

export const getTrackingAnonymousId = () => {
  const option = {}

  // Check user function exists to prevent the error from adBlock.
  if (typeof window.analytics.user === 'function' && typeof window.analytics.user().anonymousId === 'function') {
    option.anonymousId = window.analytics.user().anonymousId()
  }

  return option
}

// Only append email details to tracking event if it's available.
export const getEmailIfExists = (key) => getUser() && getUser().email && { [key || 'email']: getUser().email }

// Only append email details to tracking event if it's available.
export const getFieldIfExists = (key, val) => val && val !== '' && { [key]: val }
