import { JigConst, UtilsConst } from '@/constants'
import { DataTableHeadings } from '@/modules/library/types'
import { JigsListSortTerm, LoadTenantJigsPayload } from '@/store/modules/jig/types'
import { StandardObject } from '@/store/types'

const itemsPerPageOptions: number[] = [50, 100, 250, 500]
const tenantOwnedKey: string = JigConst.JigListQueryFilters.tenantOwner
const tenantSharingKey: string = JigConst.JigListQueryFilters.tenantSharing

const tenantUsersHeaders: DataTableHeadings = {
  name: {
    copy: 'Name',
    sort: true,
    isAsc: true,
  },
  email: {
    copy: 'Email',
    sort: true,
    isAsc: true,
  },
  roles: {
    copy: 'Role',
    sort: true,
    isAsc: true,
  },
  extraPermissionNames: {
    copy: 'Additional permissions',
    sort: false,
  },
  action: {
    copy: ' ',
    sort: false,
  },
}

const JigsListSharedHeaders: DataTableHeadings = {
  preview: {
    copy: 'Preview',
    sort: false,
  },
  name: {
    copy: 'Name',
    sort: true,
    isAsc: true,
    sortBy: 'ProjectName',
    sortByQuery: JigConst.JigDBFields.name,
  },
  dateUpdated: {
    copy: 'Updated',
    sort: true,
    isAsc: false,
    sortBy: 'DateUpdated',
    sortByQuery: JigConst.JigDBFields.dateUpdated,
  },
  visibility: {
    copy: 'Visibility',
    sort: true,
    sortByQuery: JigConst.JigDBFields.visibility,
    isAsc: true,
  },
  views: {
    copy: 'Views',
    sort: true,
    sortByQuery: JigConst.JigDBFields.views,
    isAsc: true,
  },
}

const teamJigsHeaders: DataTableHeadings = {
  owner: {
    copy: 'Owner',
    sort: true,
    sortByQuery: JigConst.JigDBFields.uid,
    isAsc: true,
  },
  teamVisibility: {
    copy: 'Team rights',
    sort: true,
    isAsc: true,
    sortFunc: () => {},
  },
}

const myJigsLoadConfig: LoadTenantJigsPayload = {
  page: 1,
  limit: UtilsConst.defaultUsersJigsPerPage,
  filters: {
    [JigConst.JigListQueryFilters.ownerUserId]: {
      action: 'eq',
      value: null,
    },
    [JigConst.JigListQueryFilters.sharedWithUser]: {
      action: 'eq',
      value: true,
    },
  },
  sort: {
    byId: 'dateUpdated',
    by: JigConst.JigDBFields.dateUpdated,
    value: JigConst.jigsListSortTerm.desc,
  },
}

const teamJigsLoadConfig: LoadTenantJigsPayload = {
  page: 1,
  limit: UtilsConst.defaultUsersJigsPerPage,
  filters: {
    [tenantOwnedKey]: {
      action: 'eq',
      value: '',
    },
    [tenantSharingKey]: {
      action: 'not_eq',
      value: 'view',
    },
  },
  sort: {
    byId: 'dateUpdated',
    by: JigConst.JigDBFields.dateUpdated,
    value: JigConst.jigsListSortTerm.desc,
  },
}

const shareOptionFilters: StandardObject = {
  all: {
    [tenantOwnedKey]: { ...teamJigsLoadConfig.filters[tenantOwnedKey] },
  },
  shared: {},
  noneShared: {
    [tenantOwnedKey]: { ...teamJigsLoadConfig.filters[tenantOwnedKey] },
    [tenantSharingKey]: {
      ...teamJigsLoadConfig.filters[tenantSharingKey],
      action: 'neq',
    },
  },
}

const teamJigsOptions: StandardObject[] = [
  {
    text: 'Show all',
    filters: shareOptionFilters.all,
    value: '',
  },
  {
    text: 'Show shared with team',
    filters: shareOptionFilters.shared,
    value: 'shared',
  },
  {
    text: 'Show non-shared',
    filters: shareOptionFilters.noneShared,
    value: 'noneShared',
  },
]

const tenantsHeaders: DataTableHeadings = {
  id: {
    copy: 'ID',
    sort: false,
  },
  logo: {
    copy: 'Logo',
    sort: false,
  },
  name: {
    copy: 'Team name',
    sort: false,
  },
  count: {
    copy: 'User count',
    sort: false,
  },
  tier: {
    copy: 'Subscription tier',
    sort: false,
  },
  pricing: {
    copy: 'Pricing set',
    sort: false,
  },
  active: {
    copy: 'Active',
    sort: false,
  },
  action: {
    copy: 'Action',
    sort: false,
  },
}

export class DataTableConfig {
  public static sortTerm: JigsListSortTerm = {
    asc: JigConst.jigsListSortTerm.asc,
    desc: JigConst.jigsListSortTerm.desc,
  }

  public static tenantUsers: StandardObject = {
    headers: tenantUsersHeaders,
  }

  public static jigsList: StandardObject = {
    headers: {
      shared: JigsListSharedHeaders,
      teamJigs: teamJigsHeaders,
    },
    itemsPerPageOptions,
    loadConfig: {
      my: myJigsLoadConfig,
      team: teamJigsLoadConfig,
      shareOptionFilters,
      shareOptions: [...teamJigsOptions],
    },
  }

  public static tenants: StandardObject = {
    headers: tenantsHeaders,
    itemsPerPageOptions: [50, 100],
  }
}
